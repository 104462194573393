<template>
  <Label
    class="inline-flex items-start gap-x-2 cursor-pointer custom-checkbox"
    :class="[computedDisabled && 'cursor-default']"
    v-bind="$attrs"
  >
    <div class="inline-block relative">
      <input
        v-model="computedValue"
        :disabled="computedDisabled"
        :required="computedRequired"
        type="checkbox"
        class="w-0 h-0 absolute left-0 top-0 peer opacity-0"
        :value="value"
      />
      <IconCheckmark
        :class="isError && '!border-error-red'"
        class="stroke-transparent peer-focus:border-light-grey icon-checkmark w-6 h-6 min-h-6 min-w-6 max-w-6 max-h-6 border border-grey rounded-none peer-checked:bg-black peer-checked:border-black peer-checked:stroke-white peer-disabled:bg-medium-grey peer-disabled:stroke-medium-grey transition-colors"
      />
    </div>
    <span
      v-if="label"
      :class="[isError && '!text-error-red', computedDisabled && 'text-grey']"
      v-html="label"
    >
    </span>
  </Label>
  <div v-if="supportText">
    <span
      :class="isError && '!text-error-red'"
      class="ps-2 text-m-sm md:text-sm text-dark-grey"
      >{{ supportText }}</span
    >
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Label from "@/components/atoms/Label";
import { IconCheckmark } from "@/components/atoms/Icon";
const props = defineProps<{
  label?: string;
  disabled?: boolean;
  required?: boolean;
  modelValue?: boolean;
  value?: boolean;
  supportText?: string;
  isError?: boolean;
}>();

const emit = defineEmits(["change", "update:modelValue"]);

const computedDisabled = computed<boolean>(() => props.disabled || false);
const computedRequired = computed<boolean>(() => props.required || false);

const computedValue = computed<boolean>({
  get: () => props.modelValue || props.value,
  set: (value: boolean) => {
    emit("change", value);
    emit("update:modelValue", value);
  },
});
</script>
